import { createApp } from 'vue';
import { createStore } from "vuex";
import axios from "axios";
import { auth } from "../store/auth.module";
import Main from '../pages/Main';

const instance = axios.create();
const store = createStore({
  modules: {
    auth,
  },
});

let app=createApp(Main);
app.config.globalProperties.axios=instance;
app.config.globalProperties.$store=store;
app.use(store);
app.provide('store' , store);
app.mount("#main");