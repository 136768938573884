<template>
  <div />
</template>
<script>

export default {
  name: "Main",
  mounted() {
    window.addEventListener('storage', (event) => {
      if (event.storageArea != localStorage) return;
      if (event.key === 'logged-in') {
        if (event.newValue === 'false') {
          window.localStorage.setItem('show-ads', true);
          window.localStorage.removeItem('user-token');
          window.location.reload();
        } else {
          window.location.reload();
        }
      }
    });
  },
}
</script>